.react-tags input, .react-tags__container > li {
  font-size: 12px;
  height: 26px;
  color: #626166;
  border-radius: 12px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: inline-block;
  padding: 0 9px; }

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translate3d(8, 0, 0); }
  100% {
    -webkit-transform: translate3d(0, 0, 0); } }

@-moz-keyframes slide-left {
  0% {
    -moz-transform: translate3d(8px, 0, 0); }
  100% {
    -moz-transform: translate3d(0, 0, 0); } }

@-o-keyframes slide-left {
  0% {
    -o-transform: translate3d(8px, 0, 0); }
  100% {
    -o-transform: translate3d(0, 0, 0); } }

@keyframes slide-left {
  0% {
    transform: translate3d(8px, 0, 0); }
  100% {
    transform: translate3d(0, 0, 0); } }

.react-tags input {
  transition: border-color 0.3s ease;
  background: #FFF;
  border: 1px solid #D9D8DD;
  height: 24px;
  margin-top: 5px; }
  .react-tags input::-webkit-input-placeholder {
    color: rgba(98, 97, 102, 0.5); }
  .react-tags input::-moz-placeholder {
    color: rgba(98, 97, 102, 0.5); }
  .react-tags input:-ms-input-placeholder {
    color: rgba(98, 97, 102, 0.5); }
  .react-tags input::-ms-clear {
    display: none; }
  .react-tags input:focus {
    outline: none;
    border-color: #b1afb9; }

.react-tags__container {
  display: inline;
  margin: 0;
  padding: 0;
  list-style: none; }

.react-tags__container > li {
  line-height: 26px;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -webkit-animation: slide-left 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  -moz-animation: slide-left 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  -o-animation: slide-left 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  animation: slide-left 0.8s cubic-bezier(0.19, 1, 0.22, 1);
  background: #D9D8DD;
  margin: 5px 5px 0 0;
  transition: background 0.3s ease;
  cursor: default; }
  .react-tags__container > li:hover, .react-tags__container > li.active {
    background: #c9c8cf; }

.react-tags__container a {
  font-size: 12px;
  color: #000;
  cursor: pointer;
  transition: color 0.3s ease;
  display: inline-block;
  margin-left: 7px;
  text-decoration: none; }
  .react-tags__container a:hover {
    color: #666666; }

.react-tags__container i {
  font-style: normal; }

.react-tags__container_readonly {
  pointer-events: none; }
